import { useState, useCallback } from 'react';

function useModal(initialMode = false) {
  const [isModalOpen, setModalState] = useState(initialMode);
  const setModalOpen = useCallback(() => setModalState(true), []);
  const setModalClose = useCallback(() => setModalState(false), []);
  const toggle = () => setModalState(!isModalOpen);
  return [isModalOpen, setModalOpen, setModalClose, toggle];
}

export { useModal };
